import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { Stack } from "@mui/material";
import { graphql, useStaticQuery } from "gatsby";
import { useEffect } from "react";

import Overview from "../../components/Overview";
import { SEO } from "../../components/SEO";
import { HeadingL, HeadingM } from "../../components/Typography";
import { useParams } from "../../hooks/useParams";

interface imageProps {
	intro_image: {
		publicURL: string;
	};
}

const introImage = (): imageProps => {
	return useStaticQuery<Queries.PrihlaseniProZamestnanceLoginImageQuery>(
		graphql`
			query PrihlaseniProZamestnanceLoginImage {
				intro_image: file(relativePath: { eq: "login.png" }) {
					publicURL
				}
			}
		`
	);
};

function AuthChoicePage(): JSX.Element {
	const image = introImage();

	const [targetUserId] = useParams({ name: "targetUserId", required: false });
	const { instance, inProgress } = useMsal();

	useEffect(() => {
		if (inProgress != InteractionStatus.None) {
			return;
		}
		const domain = process.env.GATSBY_B2C_DOMAIN as string;
		const tenantId = process.env.GATSBY_B2C_TENANT_ID as string;
		const policy = "B2C_1A_IMPERSONATIONPOLICY";
		const authority = `https://${domain}/${tenantId}/${policy}`;
		localStorage.setItem("lastUsedAuthority", authority);

		void instance.loginRedirect({
			scopes: ["openid", process.env.GATSBY_B2C_API_SCOPE || ""],
			authority,
			extraQueryParameters: { targetUserId: targetUserId || "" },
		});
	}, [instance, inProgress]);

	return (
		<Stack
			spacing={6}
			sx={{
				pb: 7,
				px: 4,
			}}
		>
			<Overview
				image={image.intro_image.publicURL}
				alt="Přihlášení"
				sx={{
					mb: -4,
					mx: -4,
				}}
			>
				<Stack spacing={4} sx={{ px: 3 }}>
					<HeadingL>Portál Moje NN</HeadingL>
				</Stack>
			</Overview>
			<Stack spacing={5}>
				<HeadingM>Zaměstnanecké přihlášení</HeadingM>
			</Stack>
		</Stack>
	);
}

export default AuthChoicePage;

export const Head = (): JSX.Element => (
	<SEO title="Přihlášení pro zaměstnance">
		<meta name="robots" content="noindex" />
	</SEO>
);
